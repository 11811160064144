export const options = [{
  name: 'Отзывы',
  id: 0,
},
{
  name: 'Трансфер',
  id: 1,
},
{
  name: 'Экскурсии',
  id: 2,
},
];
