export const text = [{
  unitTitle: 'Экскурсии в Грузии',
  unitText: 'Организуем путешествия в зависимости от ваших целей. Поможем самостоятельно отправиться в '
  + 'Можем встретить вас в аэропорту и провезти по достопримечательностям страны'
  + 'поможем посетить самые интересные места в составе группы, отдохнуть семьёй на '
  + 'побережье Батуми.'
  + 'При заказе нескольких дней экскурсий - скидка.',
  id: 1,
},
{
  unitTitle: 'Работаем из Грузии',
  unitText:
    'Мы не покупаем авиабилеты'
    + 'и не предоставляем туры из вашей страны'
    + 'работаем только на территории Грузии',
  id: 2,
},
{
  unitTitle: 'Специальное предложение',
  unitText: 'Если у вас собралась интересная компания в количестве 3-4 человек(из расчета трансфера на '
                  + 'одном автомобиле) и вы готовы провести целый день с нашими гидами, то мы обязательно сделаем вам '
                  + 'хорошую скидку. Экскурсии на весь день от 15 долларов с человека(в зависимости от направления).',
  id: 3,
},
{
  unitTitle: 'Предоставляем услуги трансфера отдельно',
  unitText: 'Трансфер бесплатен при заказе экскурсий. За отдельную плату доставим Вас в любую точку. '
                + 'Нам всегда по пути!',
  id: 4,
},
{
  unitTitle: 'Все заботы берем на себя',
  unitText: 'Все заботы по организации каждого путешествия мы берём на себя: договариваемся с '
  + 'гидами, проходим регистрации, бронируем билеты - делаем все то, на что Вам жалко тратить '
  + 'своё время. Мы работаем без предоплаты. Если закажете несколько дней эскурсий, обязательно'
  + 'сделаем хорошую скидку.',
  id: 5,
},
{
  unitTitle: 'Всегда на связи с Вами',
  unitText: 'В поездке мы всегда будем рядом и доступны по телефонам. '
                + 'В любой непредвиденной ситуации можно писать онлайн или звонить,'
                + ' и мы немедленно постараемся найти решение.',
  id: 6,
},
{
  unitTitle: 'Спасибо, что выбираете нас!',
  unitText: 'На нашем счету тысячи довольных туристов. Каждую вашу поездку мы переживаем, как свою'
  + ' собственную, и очень рады, что число '
  + 'тех, кому помогаем увидеть мир, постоянно растёт. Спасибо, что доверяете заботу о вашем отдыхе OpenGeorgia!',
  id: 7,
},
];
