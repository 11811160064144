import AddTransfer from '../../../containers/AddTransferContainer';

import './styles.scss';

export default function AdminTransfer() {
  return (
    <div className="content-container">
      <AddTransfer />
    </div>
  );
}
