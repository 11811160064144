import React from 'react';
import './styles.scss';

export function NotFound() {
  return (
    <div className="wrapper-404">
      <h1>404: Страница не найдена</h1>
    </div>
  );
}
